<template>
  <div>
    <!--zh-cn-->
    <div v-if="lang == 'zh_CN'">
      <section class="wrapper">
        <h1 id="tos">生物识别服务协议</h1>
        <p><strong>第一条 总则</strong></p>
        <p>1、本服务协议（以下简称“本协议”）是Infinity项目方（以下简称“本公司”）与本公司用户（以下简称“用户”或“您”)就“指纹支付”、 “面容支付”和“刷脸登录”等服务（以下统称“生物识别服务”）的使用等相关事项所订立的有效合约。您通过网络页面点击确认或以其他方式选择接受本协议，即表示您与本公司已达成协议并同意接受本协议的全部约定内容。</p>
        <p>2、在接受本协议之前，请您仔细阅读本协议的全部内容。 如果您不同意本协议的任意内容，或者无法准确理解本公司对条款的解释，请不要进行后续操作。</p>
        <p><strong>第二条 定义</strong></p>
        <p><strong>第三条 双方权利义务</strong></p>
        <p>1、面容ID：指由苹果公司（Apple Inc.）开发的、搭载于iPhone手机及其他设备的一种基于面部识别的身份认证技术。</p>
        <p>2、面容支付：指用户开通面容支付功能后，用户使用本公司支付服务时，通过存在其手机或其他智能终端设备中的面容ID验证即可完成支付的服务。</p>
        <p>3、指纹支付：指用户开通指纹支付功能后，用户使用本公司支付服务时，通过存在其手机或其他智能端设备中的指纹验证即可完成支付的服务。</p>
        <p>4、刷脸登录：指用户开通刷脸登录功能后，用户通过本公司提供的人脸信息比对方式进行身份验证即可完成登录的服务。</p>
        <p><strong>第三条 双方权利义务</strong></p>
        <p><strong>一、指纹支付和面容支付服务</strong></p>
        <p><strong>1、您的指纹特征以及面容ID信息由您录入指纹、面容ID当时的载体（如手机或其他智能终端设备）及其系统保存，本公司不会保存您的指纹特征及面容ID信息。</strong></p>
        <p><strong>2、您确保在开通指纹、面容支付服务前，已在您的手机或其他智能终端设备开通指纹、面容ID验证功能。</strong></p>
        <p><strong>3、您理解并同意，在您开通指纹、面容支付功能前，您已经充分了解指纹验证或面容ID验证通过后即可以在一定额度免输支付密码、短信动态码以及进行其他任何信息验证完成支付，具体可支付额度以本公司页面提示为准。您同意并理解本公司有权根据客观情况和风险控制需要随时调整您使用本公司指纹支付功能时的免密交易额度和次数，如您不接受本公司对您的免密支付额度和次数的调整，您应停止使用本公司指纹、面容支付功能，否则，由此造成的损失由您自己承担。</strong></p>
        <p><strong>4、您可以在“和包支付”客户端内开启或关闭指纹、面容支付功能。具体可供设定的选择将以“和包支付”客户端内展示的选项为准。为了确认是您本人在某个设备上开启或关闭了指纹、面容支付功能，本公司可能需要验证您的账户密码或其他身份要素。如果您选择开启了指纹、面容支付功能，当您向本公司发出支付相关指令时，本公司将优先通过指纹、面容支付功能来确认您的指令。</strong></p>
        <p><strong>5、您在开通指纹、面容支付服务后，您向本公司发出支付相关指令时，如能通过您手机或其他智能终端设备中的指纹、面容ID验证，本公司将不可撤销地执行您的相关指令；请您注意保管好手机或其他智能终端设备，避免他人在您的手机或其他智能终端设备中存入其指纹、面容ID使您遭受损失，您应当对本公司执行您通过指纹、面容ID验证而向本公司发出的指令所产生的结果独立承担责任。</strong></p>
        <p><strong>6、您充分理解，因部分型号的手机或智能终端可能存在识别、比对和验证逻辑方面的问题（如将其他用途的指纹、面容ID识别成支付所用的面容ID 等），可能给您造成损失。如您不能接受该损失风险，请您不要进行后续操作，如您继续通过网络页面点击确认或以其他方式选择接受本协议，则表示您已充分认识并理解该损失风险，并愿意承受该损失风险。</strong></p>
        <p><strong>7、您理解并同意，当且仅当您完全满足下列条件时，您才有权申请开通本公司指纹、面容支付功能：</strong></p>
        <p><strong>1）您使用的手机版本支持指纹、面容支付功能；</strong></p>
        <p><strong>2）您使用的IOS设备未越狱（即未对IOS系统限制用户存储读写权限进行破解操作）；</strong></p>
        <p><strong>3）您已进行本公司实名认证。</strong></p>
        <p><strong>8、您同意并理解，本公司指纹、面容支付技术上遵循“一纹一机”、“一面一机”规则，即如您在某一特定设备上开启指纹、面容支付功能后，该指纹、面容支付功能仅对该特定设备有效；如您在某一特定设备上已经开启指纹、面容支付功能后，希望在其他设备再次开启本公司指纹、面容支付功能，基于交易风险和资金安全方面的考虑，本公司将会关闭您在先前设备上开启的本公司指纹、面容支付功能。</strong></p>
        <p><strong>9、除本协议另有规定外，本公司在提前邮件通知您或在网站公告的情况下，可以单方面终止向您提供生物服务，并不承担任何赔偿责任。本公司在终止提供生物服务后，若发现您之前存在违法或违反本协议目的的使用行为，给本公司造成损失的，则本公司仍可据此要求您承担相应赔偿责任并保留通过司法途径予以解决的权利。</strong></p>
        <p><strong>第四条 协议修改</strong></p>
        <p>1、为了进一步改善用户体验，本公司将会持续更新服务，本协议也会随之更新，更新内容自公布之日起生效。如果更新的内容涉及您的主要权利或责任，本公司会已公告、客户端通知或短信等方式向您进行提示。</p>
        <p>2、如果您不同意更新后的协议内容，您有权停止使用相关服务。如果您继续使用本协议约定的服务，则视为您接受并认可更新后的协议。</p>
        <p><strong>第五条 争议的解决</strong></p>
        <p><strong>双方在履行本协议的过程中，如发生争议，应协商解决。协商不成的，任何一方均可向本公司住所地有管辖权的人民法院提起诉讼。</strong></p>
        <p><strong>第六条 免责条款</strong></p>
        <p>您同意因下列原因导致本公司无法正常提供服务，本公司不承担责任：</p>
        <p>1、本公司系统停机维护或升级；</p>
        <p>2、因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</p>
        <p>3、用户的电脑软硬件和通信线路、供电线路出现故障的；</p>
        <p>4、用户操作不当或通过非本公司授权或认可的方式使用本公司服务的；</p>
        <p>4、用户操作不当或通过非本公司授权或认可的方式使用本公司服务的；</p>
        <p>5、因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因 、第三方服务瑕疵或政府行为等原因。</p>
        <p>尽管有前款约定，本公司将采取合理行动积极促使服务恢复正常。</p>
        <p><strong>第七条 协议的解释</strong></p>
        <p>本协议的成立、生效、履行和解释，均适用中华人民共和国法律。本服务协议未规定部分依照《和包业务用户服务协议》及相关附属规则予以履行；本协议与《和包业务用户服务协议》及相关附属规则不一致的，以本协议为准。</p>
      </section>
    </div>

    <!--en-->
    <div v-else-if="lang == 'en'">
      <section class="wrapper">
        <h1 id="tos">Biometric Services Agreement</h1>
        <p><strong>Article 1 General</strong></p>
        <p>1. This service agreement (hereinafter referred to as "this agreement") is the agreement between the Infinity project party (hereinafter referred to as "the company") and the users of the company (hereinafter referred to as "users" or "you") regarding "fingerprint payment", " A valid contract for the use of services such as "face payment" and "swipe face login" (hereinafter collectively referred to as "biometric services"). If you click to confirm through the web page or choose to accept this agreement in other ways, it means that you and the company have reached an agreement and agree to accept all the agreed content of this agreement. </p>
        <p>2. Before accepting this agreement, please read the entire contents of this agreement carefully. If you do not agree with any content of this agreement, or cannot accurately understand the company's interpretation of the terms, please do not follow up. </p>
        <p><strong>Second Definitions</strong></p>
        <p><strong>Article 3 Rights and obligations of both parties</strong></p>
        <p>1. Face ID: refers to a facial recognition-based identity authentication technology developed by Apple Inc. and mounted on iPhones and other devices. </p>
        <p>2. Face payment: refers to the service in which the user can complete the payment by verifying the face ID stored in his mobile phone or other smart terminal device when using the company's payment service after the user has activated the face payment function. </p>
        <p>3. Fingerprint payment: After the user activates the fingerprint payment function, when the user uses the company's payment service, the user can complete the payment through the fingerprint verification stored in his mobile phone or other smart terminal device. </p>
        <p>4. Login by swiping face: After the user activates the login function by swiping face, the user can complete the login by performing identity verification through the face information comparison method provided by the company. </p>
        <p><strong>Article 3 Rights and obligations of both parties</strong></p>
        <p><strong>I. Fingerprint payment and face payment services</strong></p>
        <p><strong>1. Your fingerprint features and face ID information are stored by the carrier (such as a mobile phone or other smart terminal device) and the system on which you entered your fingerprint and face ID. The company will not save your fingerprint features and Face ID information. </strong></p>
        <p><strong>2. Before activating the fingerprint and face payment service, you must enable the fingerprint and face ID verification function on your mobile phone or other smart terminal device. </strong></p>
        <p><strong>3. You understand and agree that before you activate the fingerprint and face payment function, you have fully understood that after passing the fingerprint verification or face ID verification, you can enter a certain amount of payment password, SMS dynamic code and payment Any other information is verified to complete the payment, and the specific payment amount is subject to the prompt on the company's page. You agree and understand that the company has the right to adjust the amount and times of password-free transactions when you use the company's fingerprint payment function at any time according to the objective situation and risk control needs. , you should stop using the company's fingerprint and face payment function, otherwise, you will be responsible for the loss caused by it. </strong></p>
        <p><strong>4. You can enable or disable the fingerprint and face payment function in the "Hebao Payment" client. The specific options available for setting will be subject to the options displayed in the "Hebao Payment" client. In order to confirm that it is you who has turned on or turned off the fingerprint and face payment function on a certain device, the company may need to verify your account password or other identity elements. If you choose to enable the fingerprint and face payment function, when you send payment-related instructions to the company, the company will give priority to confirming your instructions through the fingerprint and face payment function. </strong></p>
        <p><strong>5. After you have activated the fingerprint and face payment service, if you can pass the fingerprint and face ID verification in your mobile phone or other smart terminal device when you send payment-related instructions to the company, the company will not be able to Execute your relevant instructions revocably; please take care to keep your mobile phone or other smart terminal equipment to prevent others from depositing their fingerprints and face IDs in your mobile phone or other smart terminal equipment and cause you to suffer losses. We are solely responsible for the results of instructions issued to the company through fingerprint and face ID verification. </strong></p>
        <p><strong>6. You fully understand that some models of mobile phones or smart terminals may have problems in identification, comparison and verification logic (such as recognizing fingerprints and face IDs for other purposes as face IDs used for payment, etc. ), which may cause you losses. If you cannot accept the risk of loss, please do not carry out follow-up operations. If you continue to click to confirm through the web page or choose to accept this agreement in other ways, it means that you have fully recognized and understood the risk of loss and are willing to bear the risk of loss. . </strong></p>
        <p><strong>7. You understand and agree that if and only if you fully meet the following conditions, you have the right to apply for the activation of the company's fingerprint and face payment function:</strong></p>
        <p><strong>1) The mobile phone version you are using supports fingerprint and face payment functions;</strong></p>
        <p><strong>2) The IOS device you are using is not jailbroken (that is, you have not cracked the IOS system's restricted user storage read and write permissions);</strong></p>
        <p><strong>3) You have already verified the company's real name. </strong></p>
        <p><strong>8. You agree and understand that the company's fingerprint and face payment technology follows the rules of "one fingerprint, one machine" and "one face, one machine", that is, if you enable fingerprint and face payment on a specific device After the function, the fingerprint and face payment function is only valid for the specific device; if you have enabled the fingerprint and face payment function on a specific device, you want to enable the company's fingerprint and face payment function again on other devices. Based on transaction risks For the consideration of financial security and financial security, the company will close the fingerprint and face payment function of the company that you have opened on the previous device. </strong></p>
        <p><strong>9. Except as otherwise stipulated in this agreement, the company may unilaterally terminate the provision of biological services to you by notifying you in advance by e-mail or making an announcement on the website, without any liability for compensation. After the company terminates the provision of biological services, if it finds that you have previously used illegal or contrary to the purpose of this agreement, causing losses to the company, the company can still require you to bear the corresponding liability for compensation and reserves the right to make compensation through judicial channels. the right to resolve. </strong></p>
        <p><strong>Article 4 Amendments to the Agreement</strong></p>
        <p>1. In order to further improve the user experience, the company will continue to update the service, and this agreement will also be updated accordingly, and the updated content will take effect from the date of publication. If the updated content involves your main rights or responsibilities, the company will notify you through announcements, client notifications or text messages. </p>
        <p>2. If you do not agree with the updated agreement, you have the right to stop using related services. If you continue to use the services agreed in this agreement, it is deemed that you accept and recognize the updated agreement. </p>
        <p><strong>Article 5 Resolution of Disputes</strong></p>
        <p><strong>In the process of performing this agreement, if there is a dispute between the two parties, they should negotiate and resolve it. If the negotiation fails, either party may file a lawsuit with the people's court with jurisdiction in the place where the company is domiciled. </strong></p>
        <p><strong>Article 6 Disclaimer</strong></p>
        <p>You agree that the company is not responsible for the failure of the company to provide services due to the following reasons:</p>
        <p>1. The company's system is shut down for maintenance or upgrade;</p>
        <p>2. Due to force majeure such as typhoons, earthquakes, floods, lightning or terrorist attacks;</p>
        <p>3. The user's computer hardware and software, communication lines, and power supply lines are faulty;</p>
        <p>4. The user operates improperly or uses the company's services in a way not authorized or approved by the company;</p>
        <p>4. The user operates improperly or uses the company's services in a way not authorized or approved by the company;</p>
        <p>5. Due to viruses, Trojan horses, malicious program attacks, network congestion, system instability, system or equipment failures, communication failures, power failures, bank reasons, third-party service defects or government actions, etc. </p>
        <p>Despite the agreement in the preceding paragraph, the company will take reasonable actions to actively promote the service to return to normal. </p>
        <p><strong>Article 7 Interpretation of the Agreement</strong></p>
        <p>The establishment, entry into force, performance and interpretation of this agreement shall be governed by the laws of the People's Republic of China. The parts not stipulated in this service agreement shall be performed in accordance with the "Hebao Business User Service Agreement" and relevant subsidiary rules; if this agreement is inconsistent with the "Hebao Business User Service Agreement" and relevant subsidiary rules, this agreement shall prevail. </p>
      </section>
    </div>

    <!--zh_TW-->
    <div v-else>
      <section class="wrapper">
        <h1 id="tos">生物識別服務協議</h1>
        <p><strong>第一條 總則</strong></p>
        <p>1、本服務協議（以下簡稱“本協議”）是Infinity項目方（以下簡稱“本公司”）與本公司用戶（以下簡稱“用戶”或“您”)就“指紋支付”、 “面容支付”和“刷臉登錄”等服務（以下統稱“生物識別服務”）的使用等相關事項所訂立的有效合約。您通過網絡頁麵點擊確認或以其他方式選擇接受本協議，即表示您與本公司已達成協議並同意接受本協議的全部約定內容。 </p>
        <p>2、在接受本協議之前，請您仔細閱讀本協議的全部內容。如果您不同意本協議的任意內容，或者無法準確理解本公司對條款的解釋，請不要進行後續操作。 </p>
        <p><strong>第二條 定義</strong></p>
        <p><strong>第三條 雙方權利義務</strong></p>
        <p>1、面容ID：指由蘋果公司（Apple Inc.）開發的、搭載於iPhone手機及其他設備的一種基於面部識別的身份認證技術。 </p>
        <p>2、面容支付：指用戶開通面容支付功能後，用戶使用本公司支付服務時，通過存在其手機或其他智能終端設備中的面容ID驗證即可完成支付的服務。 </p>
        <p>3、指紋支付：指用戶開通指紋支付功能後，用戶使用本公司支付服務時，通過存在其手機或其他智能端設備中的指紋驗證即可完成支付的服務。 </p>
        <p>4、刷臉登錄：指用戶開通刷臉登錄功能後，用戶通過本公司提供的人臉信息比對方式進行身份驗證即可完成登錄的服務。 </p>
        <p><strong>第三條 雙方權利義務</strong></p>
        <p><strong>一、指紋支付和麵容支付服務</strong></p>
        <p><strong>1、您的指紋特徵以及面容ID信息由您錄入指紋、面容ID當時的載體（如手機或其他智能終端設備）及其係統保存，本公司不會保存您的指紋特徵及面容ID信息。 </strong></p>
        <p><strong>2、您確保在開通指紋、面容支付服務前，已在您的手機或其他智能終端設備開通指紋、面容ID驗證功能。 </strong></p>
        <p><strong>3、您理解並同意，在您開通指紋、面容支付功能前，您已經充分了解指紋驗證或面容ID驗證通過後即可以在一定額度免輸支付密碼、短信動態碼以及進行其他任何信息驗證完成支付，具體可支付額度以本公司頁面提示為準。您同意並理解本公司有權根據客觀情況和風險控制需要隨時調整您使用本公司指紋支付功能時的免密交易額度和次數，如您不接受本公司對您的免密支付額度和次數的調整，您應停止使用本公司指紋、面容支付功能，否則，由此造成的損失由您自己承擔。 </strong></p>
        <p><strong>4、您可以在“和包支付”客戶端內開啟或關閉指紋、面容支付功能。具體可供設定的選擇將以“和包支付”客戶端內展示的選項為準。為了確認是您本人在某個設備上開啟或關閉了指紋、面容支付功能，本公司可能需要驗證您的賬戶密碼或其他身份要素。如果您選擇開啟了指紋、面容支付功能，當您向本公司發出支付相關指令時，本公司將優先通過指紋、面容支付功能來確認您的指令。 </strong></p>
        <p><strong>5、您在開通指紋、面容支付服務後，您向本公司發出支付相關指令時，如能通過您手機或其他智能終端設備中的指紋、面容ID驗證，本公司將不可撤銷地執行您的相關指令；請您注意保管好手機或其他智能終端設備，避免他人在您的手機或其他智能終端設備中存入其指紋、面容ID使您遭受損失，您應當對本公司執行您通過指紋、面容ID驗證而向本公司發出的指令所產生的結果獨立承擔責任。 </strong></p>
        <p><strong>6、您充分理解，因部分型號的手機或智能終端可能存在識別、比對和驗證邏輯方面的問題（如將其他用途的指紋、面容ID識別成支付所用的面容ID 等），可能給您造成損失。如您不能接受該損失風險，請您不要進行後續操作，如您繼續通過網絡頁麵點擊確認或以其他方式選擇接受本協議，則表示您已充分認識並理解該損失風險，並願意承受該損失風險。 </strong></p>
        <p><strong>7、您理解並同意，當且僅當您完全滿足下列條件時，您才有權申請開通本公司指紋、面容支付功能：</strong></p>
        <p><strong>1）您使用的手機版本支持指紋、面容支付功能；</strong></p>
        <p><strong>2）您使用的IOS設備未越獄（即未對IOS系統限制用戶存儲讀寫權限進行破解操作）；</strong></p>
        <p><strong>3）您已進行本公司實名認證。 </strong></p>
        <p><strong>8、您同意並理解，本公司指紋、面容支付技術上遵循“一紋一機”、“一面一機”規則，即如您在某一特定設備上開啟指紋、面容支付功能後，該指紋、面容支付功能僅對該特定設備有效；如您在某一特定設備上已經開啟指紋、面容支付功能後，希望在其他設備再次開啟本公司指紋、面容支付功能，基於交易風險和資金安全方面的考慮，本公司將會關閉您在先前設備上開啟的本公司指紋、面容支付功能。 </strong></p>
        <p><strong>9、除本協議另有規定外，本公司在提前郵件通知您或在網站公告的情況下，可以單方面終止向您提供生物服務，並不承擔任何賠償責任。本公司在終止提供生物服務後，若發現您之前存在違法或違反本協議目的的使用行為，給本公司造成損失的，則本公司仍可據此要求您承擔相應賠償責任並保留通過司法途徑予以解決的權利。 </strong></p>
        <p><strong>第四條 協議修改</strong></p>
        <p>1、為了進一步改善用戶體驗，本公司將會持續更新服務，本協議也會隨之更新，更新內容自公佈之日起生效。如果更新的內容涉及您的主要權利或責任，本公司會已公告、客戶端通知或短信等方式向您進行提示。 </p>
        <p>2、如果您不同意更新後的協議內容，您有權停止使用相關服務。如果您繼續使用本協議約定的服務，則視為您接受並認可更新後的協議。 </p>
        <p><strong>第五條 爭議的解決</strong></p>
        <p><strong>雙方在履行本協議的過程中，如發生爭議，應協商解決。協商不成的，任何一方均可向本公司住所地有管轄權的人民法院提起訴訟。 </strong></p>
        <p><strong>第六條 免責條款</strong></p>
        <p>您同意因下列原因導致本公司無法正常提供服務，本公司不承擔責任：</p>
        <p>1、本公司係統停機維護或升級；</p>
        <p>2、因颱風、地震、洪水、雷電或恐怖襲擊等不可抗力原因；</p>
        <p>3、用戶的電腦軟硬件和通信線路、供電線路出現故障的；</p>
        <p>4、用戶操作不當或通過非本公司授權或認可的方式使用本公司服務的；</p>
        <p>4、用戶操作不當或通過非本公司授權或認可的方式使用本公司服務的；</p>
        <p>5、因病毒、木馬、惡意程序攻擊、網絡擁堵、系統不穩定、系統或設備故障、通訊故障、電力故障、銀行原因 、第三方服務瑕疵或政府行為等原因。 </p>
        <p>儘管有前款約定，本公司將採取合理行動積極促使服務恢復正常。 </p>
        <p><strong>第七條 協議的解釋</strong></p>
        <p>本協議的成立、生效、履行和解釋，均適用中華人民共和國法律。本服務協議未規定部分依照《和包業務用戶服務協議》及相關附屬規則予以履行；本協議與《和包業務用戶服務協議》及相關附屬規則不一致的，以本協議為準。 </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TouchID',
  components: {},
  props: {},
  data() {
    return {
      lang: this.$route.params.lang || 'zh_TW'
    }
  },
  computed: {},
  methods: {},
  mounted() {

  },
}
</script>

<style>

</style>
